/* Customize your main colors in :root variables */
:root {
  --main-background-color: #121213;
  --card-background-color: #51576D;
  --card-background-lighter-color: #7A7F92;
  --main-text-color:#F7F6F4;
  --title-text-color:#3CBA8B;
}

body{
  background:radial-gradient(circle at 50% 100%, rgba(0, 0, 0, 1) 0%, rgba(0, 178, 255, 1) 100%);
  /*background-color:var(--main-background-color);
  /*background: url("img/background.png");
  background-size: cover;*/

  font-family: 'Montserrat', sans-serif;
  font-size: 1.1em;
}

h2, p{
  color: var(--main-text-color);
}

h3{
font-weight: normal;
}
